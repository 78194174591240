<template>
  <div id="materialProductStock">
    <el-dialog
      :title="materialProductStockFormTitle"
      width="1200px"
      :visible.sync="materialProductStockDialogVisible"
      :close-on-click-modal="false"
      @close="materialProductStockDialogClose"
    >
      <el-form
        ref="materialProductStockFormRef"
        :model="materialProductStockForm"
        :rules="materialProductStockFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品编号" prop="productNo">
              <el-input
                v-model="materialProductStockForm.productNo"
                placeholder="请输入产品编号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="materialProductStockForm.productName"
                placeholder="请输入产品名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="materialProductStockForm.spec"
                placeholder="请输入型号规格"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最低库存" prop="minStock">
              <el-input
                v-model="materialProductStockForm.minStock"
                placeholder="请输入最低库存"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批指令通知单" prop="remarks2">
              <el-input
                v-model="materialProductStockForm.remarks2"
                placeholder="请输入批指令通知单 "
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="materialProductStockForm.remarks"
                placeholder="请输入备注"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar>
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <!-- <vxe-table-column
              field="materialNo"
              title="物料编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            /> -->
            <vxe-table-column field="materialId" title="物料编号">
              <template v-slot="{ row, column }">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择物料"
                  clearable
                  filterable
                  @change="materialPurchaseStockChange(row)"
                >
                  <el-option
                    v-for="item in materialPurchaseStockList"
                    :key="item.id"
                    :label="item.materialNo"
                    :value="item.id"
                  >
                    <span style="float: left">{{ item.materialNo }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.productName }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="productName"
              title="名称"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="spec"
              title="型号规格"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="manufactor"
              title="厂家"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="materialProductStockDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialProductStockFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品编号">
        <el-input
          v-model="searchForm.productNo"
          placeholder="请输入产品编号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CC_MATERIAL_PRODUCT_STOCK_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialProductStockPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="productNo" label="产品编号" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="minStock" label="最低库存" />
      <el-table-column prop="realStock" label="实际库存">
        <template slot-scope="scope">
          <span
            :style="{
              color: scope.row.realStock < scope.row.minStock ? 'red' : '#000',
            }"
          >{{ scope.row.realStock }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks2" label="批指令通知单">
        <template slot-scope="scope">
          <span style="color:blue">{{ scope.row.remarks2 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CC_MATERIAL_PRODUCT_STOCK_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CC_MATERIAL_PRODUCT_STOCK_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['CC_MATERIAL_PRODUCT_STOCK_INFO'])"
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialProductStockPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMaterialProductStock,
  deleteMaterialProductStock,
  updateMaterialProductStock,
  selectMaterialProductStockInfo,
  selectMaterialProductStockList
} from '@/api/storage/materialProductStock'
import { selectMaterialPurchaseStockList } from '@/api/storage/materialPurchaseStock'

export default {
  data () {
    return {
      materialProductStockDialogVisible: false,
      materialProductStockFormTitle: '',
      materialProductStockForm: {
        id: '',
        productNo: '',
        productName: '',
        spec: '',
        minStock: '',
        remarks: '',
        remarks2: '',
        type: 1,
        detailJson: ''
      },
      materialProductStockFormRules: {
        productNo: [
          {
            required: true,
            message: '产品编号不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      materialProductStockPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productNo: '',
        type: 1
      },
      detailList: [],
      materialPurchaseStockList: []
    }
  },
  created () {
    selectMaterialProductStockList(this.searchForm).then((res) => {
      this.materialProductStockPage = res
    })
    selectMaterialPurchaseStockList({ type: 1 }).then((res) => {
      this.materialPurchaseStockList = res.list
    })
  },
  methods: {
    materialProductStockDialogClose () {
      this.$refs.materialProductStockFormRef.resetFields()
      this.detailList = []
    },
    materialProductStockFormSubmit () {
      if (this.materialProductStockFormTitle === '产品管理详情') {
        this.materialProductStockDialogVisible = false
        return
      }
      this.$refs.materialProductStockFormRef.validate(async (valid) => {
        if (valid) {
          this.materialProductStockForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.materialProductStockFormTitle === '新增产品管理') {
            await addMaterialProductStock(this.materialProductStockForm)
          } else if (this.materialProductStockFormTitle === '修改产品管理') {
            await updateMaterialProductStock(this.materialProductStockForm)
          }
          this.materialProductStockPage = await selectMaterialProductStockList(this.searchForm)
          this.materialProductStockDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialProductStockFormTitle = '新增产品管理'
      this.materialProductStockDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialProductStock(row.id)
        if (
          this.materialProductStockPage.list.length === 1
          && this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--
        }
        this.materialProductStockPage = await selectMaterialProductStockList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialProductStockFormTitle = '修改产品管理'
      this.materialProductStockDialogVisible = true
      this.selectMaterialProductStockInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialProductStockFormTitle = '产品管理详情'
      this.materialProductStockDialogVisible = true
      this.selectMaterialProductStockInfoById(row.id)
    },
    selectMaterialProductStockInfoById (id) {
      selectMaterialProductStockInfo(id).then((res) => {
        this.materialProductStockForm.id = res.id
        this.materialProductStockForm.productNo = res.productNo
        this.materialProductStockForm.productName = res.productName
        this.materialProductStockForm.spec = res.spec
        this.materialProductStockForm.minStock = res.minStock
        this.materialProductStockForm.remarks = res.remarks
        this.materialProductStockForm.remarks2 = res.remarks2
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialProductStockList(this.searchForm).then((res) => {
        this.materialProductStockPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialProductStockList(this.searchForm).then((res) => {
        this.materialProductStockPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialProductStockList(this.searchForm).then((res) => {
        this.materialProductStockPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    materialPurchaseStockChange (row) {
      if (row.materialId) {
        let materialPurchaseStock = this.materialPurchaseStockList.find((item) => item.id === row.materialId)
        row.productName = materialPurchaseStock.productName
        row.spec = materialPurchaseStock.spec
        row.manufactor = materialPurchaseStock.manufactor
      } else {
        row.productName = ''
        row.spec = ''
        row.manufactor = ''
      }
    }
  }
}
</script>

<style>
</style>
